import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';
import linkedinIcon from '../assets/icons/linkedin.png';
import instagramIcon from '../assets/icons/instagram.png';
import twitterIcon from '../assets/icons/twitter.png';
import gitlabIcon from '../assets/icons/gitlab.png';
import spotifyIcon from '../assets/icons/spotify.png';

const Wrapper = styled.div`
    position: absolute;
    top: 15%;
    left: 6%;
    z-index: 999;
`;

const bounceInUpAnimation = keyframes`
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }
    75% {
        transform: translate3d(0, 10px, 0);
    }
    90% {
        transform: translate3d(0, -5px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
`;

const bounceInDownAnimation = keyframes`
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }
    75% {
        transform: translate3d(0, -10px, 0);
    }
    90% {
        transform: translate3d(0, 5px, 0);
    }
    to {
        transform: none;
    }
`;

const MainTitle = styled.div`
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    display: block;
    font-family: 'Inconsolata', monospace;
    font-size: 5vmin;
    color: black;
`;

const MainTitleBounceIn = styled(MainTitle)`
    animation-name: ${bounceInDownAnimation};
    animation-delay: 0.5s;
`;

const SubTitleBounceIn = styled(MainTitle)`
    animation-name: ${bounceInDownAnimation};
    animation-delay: 1s;
    font-size: 8vmin;
`;

const ContentBounceIn = styled(MainTitle)`
    animation-name: ${bounceInUpAnimation};
    animation-delay: 1.5s;
    font-size: 3vmin;
    margin-top: 50px;
`;

const Heading = ({heading, subheading, content}) => {
    return <div>
        <MainTitleBounceIn>{heading}</MainTitleBounceIn>
        <SubTitleBounceIn>{subheading}</SubTitleBounceIn>
        <ContentBounceIn>{content}</ContentBounceIn>
    </div>;
};

const Icon = styled.a`
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    display: inline-block;
    margin-top: 20px;
    margin-right: 10px;
`;

const SocialIconBounceIn = styled(Icon)`
    animation-name: ${bounceInDownAnimation};
    animation-delay: 2s;
`;

const SocialIcon = ({href, icon, alt}) => {
    return <SocialIconBounceIn href={href} target='_blank'>
        <img loading={"lazy"} src={icon} alt={alt} className='social-icon'/>
    </SocialIconBounceIn>;
}

class Text extends Component {
    render() {
        return <Wrapper>
            <Heading
                heading='<?php echo "Full Stack Developer"; ?>'
                subheading='$isReadyToWork = true;'
                content='public const SOCIAL_LINKS'
            />

            <SocialIcon href='https://www.linkedin.com/in/ruslanti' icon={linkedinIcon} alt='linkedin'/>
            <SocialIcon href='https://gitlab.com/ruslanty' icon={gitlabIcon} alt='gitlab'/>
            <SocialIcon href='https://twitter.com/ruslanty' icon={twitterIcon} alt='twitter'/>
            <SocialIcon href='https://www.instagram.com/ruslanty/' icon={instagramIcon} alt='instagram'/>
            <SocialIcon href='https://open.spotify.com/user/ruslanty' icon={spotifyIcon} alt='spotify'/>
        </Wrapper>;
    }
}

export default Text;
