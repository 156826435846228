import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import * as serviceWorker from './serviceWorker';
import Main from './components/Main';

ReactDOM.render(
    <React.StrictMode>
        <Main/>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
