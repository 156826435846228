import React, {Component} from 'react';
import clip from '../assets/videos/bg.mp4';

class Video extends Component {
    render() {
        return <video className='background-video' muted loop autoPlay playsInline>
            <source src={clip} type='video/mp4'/>

            Your browser does not support the video tag.
        </video>;
    }
}

export default Video;
