import React, {Component} from 'react';
import Video from './Video';
import Text from './Text';

class Main extends Component {
    render() {
        return <div className='container'>
            <Video/>
            <Text/>
        </div>;
    }
}

export default Main;
